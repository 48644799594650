<template>
    <div class="login-info">
        <template v-if="login">
          <div class="login-form-box">
            <form class="login-info-form">
                <p class="login-info-title">登陆界面</p><br>
                <span class="marleft">用户账号: </span><br>
                <input class="marleft info-input" type="text" v-model="username" placeholder="请输入账号"/><br>
                <span class="marleft">用户密码: </span><br>
                <input class="marleft info-input" type="password" placeholder="请输入密码" v-model="password"/><br>
                <span class="marleft">验证码: </span><br>
                <div class="vCode-box">
                  <input type="text" class="marleft info-input" placeholder="请输入验证码" v-model="VerificationCode"/>
                  <span class="vCode" @click="getVcode">{{vCode}}</span>
                </div>
                <input class="submit-f marleft info-input" type="button" @click="goHome" value="提交">
                <input class="submit marleft info-input" type="button" @click="changeLogin(false, true)" value="前往注册">
            </form>
          </div>
        </template>
        <template v-if="register">
          <div class="login-form-box">
            <form class="login-info-form">
                <p class="login-info-title">注册界面</p><br>
                <span class="marleft">用户账号: </span><br/>
                <input class="marleft info-input" type="text" v-model="username" placeholder="请输入账号"/><br>
                <span class="marleft">用户密码: </span><br/>
                <input class="marleft info-input" type="password" placeholder="请输入密码" v-model="password"/><br>
                <span class="marleft">确认密码: </span><br/>
                <input class="marleft info-input" type="password" placeholder="请确认密码" v-model="confirmPassword"/><br>
                <input class="submit-f marleft info-input" type="button" @click="goLogin" value="提交">
                <input class="submit marleft info-input" type="button" @click="changeLogin(true, false)" value="已有账号">
            </form>
          </div>
        </template>
    </div>
</template>

<script>
import util from '@/util'
import { mapState } from 'vuex'
export default {
  name: 'login',
  computed: {
    ...mapState({
      Username: 'Username',
      loginStatus: 'loginStatus',
      Login: 'Login'
    })
  },
  data () {
    return {
      login: true,
      register: false,
      username: this.$store.state.username,
      password: '',
      confirmPassword: '',
      userid: '',
      time: Date(),
      erralr: 0,
      resair: 0,
      vCode: '',
      VerificationCode: ''
      // myname: ''
    }
  },
  methods: {
    goLogin () {
      console.log('username', util.trim(this.username))
      if (!util.trim(this.username) || !util.trim(this.username)) {
        window.alert('账号或密码不能为空')
        return
      }
      if (this.password !== this.confirmPassword) {
        window.alert('密码不一致，请重新输入')
        this.password = ''
        this.confirmPassword = ''
      } else {
        this.$http.get(`/register?userName=${this.username}&password=${this.password}`).then(res => {
          if (res.data.state === 0) {
            this.register = false
            this.userid = this.username
            window.localStorage.setItem('loginStatus', 'login')
            this.$store.commit('getUser', this.userid)
            this.$store.commit('setUsername', this.userid)
            this.$store.commit('setHeadimg', this.userid)
            this.$store.commit('setLogin', true)
            window.alert('注册成功，确定进入网站首页')
            this.$router.push('/')
            window.localStorage.setItem('username', this.userid)
            window.localStorage.setItem('Username', this.userid)
            window.localStorage.setItem('headimg', this.userid)
          } else {
            window.alert(res.data.message)
          }
        })
      }
    },
    changeLogin (b1, b2) {
      this.login = b1
      this.register = b2
    },
    goHome () {
      // const username = window.localStorage.getItem('username')
      // const password = window.localStorage.getItem('password')
      if (this.username) {
        if (this.vCode === this.VerificationCode) {
          this.$http.get(`/login?userName=${this.username}&password=${this.password}`).then(res => {
            if (res.data.state === 0) {
              console.log(res.data)
              this.$http.post(`/updatelogintime?userName=${this.username}`)
              this.userid = res.data.data
              this.login = false
              window.localStorage.setItem('loginStatus', 'login')
              this.$store.commit('getUser', this.userid)
              this.$store.commit('setUsername', this.userid)
              this.$store.commit('setHeadimg', this.userid)
              this.$store.commit('setLogin', true)
              window.alert('登陆成功，确定进入网站首页')
              // window.location.href = '/'
              this.$router.push('/')
              window.localStorage.setItem('username', this.userid)
              window.localStorage.setItem('Username', this.userid)
              window.localStorage.setItem('headimg', this.userid)
            } else {
              window.alert(res.data.message)
            }
          })
        } else {
          this.erralr++
          if (this.VerificationCode) {
            if (this.erralr === 1) {
              alert('验证码错误')
            }
          } else {
            if (this.erralr === 1) {
              alert('请输入验证码')
            }
          }
        }
      } else {
        alert('请输入账号密码。')
      }
      this.erralr = 0
      this.resalr = 0
    },
    getRandom (n, m) {
      n = Number(n)
      m = Number(m)
      // 确保 m 始终大于 n
      if (n > m) {
        var temp = n
        n = m
        m = temp
      }
      return Math.floor(Math.random() * (m - n) + n)
    },
    getVcode () {
      this.vCode = ''
      var codeStr = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      for (var i = 0; i < 4; i++) {
        var ran = this.getRandom(0, 62)
        this.vCode += codeStr.charAt(ran)
      }
    }
  },
  created () {
    // 获取路由中的参数
    if (this.$route.params.loginStatus === 'logout') {
      window.localStorage.clear()
      this.$store.commit('getLoginStatus', false)
      return
    }
    const loginStatus = this.$store.state.loginStatus
    if (loginStatus) {
      this.login = false
      this.register = false
      window.alert('您已经是登录状态')
      // window.location.href = '/'
      this.$router.push('/')
    }
  },
  mounted () {
    this.getVcode()
  }
}
</script>

<style scoped>
    .login-form-box{
      position: relative;
      width: 100%;
      height: 100%;
    }
    .login-info{
        height:100%;
        width:100%;
        position: absolute;
        background-color: rgb(238, 238, 238);
    }
    .login-info-form{
      background-color: rgb(247, 247, 247);
      width: 90%;
      height: 90%;
      position: absolute;
      top: 5%;
      left: 5%;
    }
    .login-info-title{
      line-height: 0.8rem;
      text-align: center;
      font-weight: 400;
      font-size: 0.3rem;
    }
    .marleft{
      width: 94%;
      margin-left: 3%;
      line-height: 0.5rem;
      font-weight: 600;
    }
    .info-input{
      height: 0.4rem;
      border: none;
      font-weight: 400;
      font-size: 0.25rem;
      background-color: rgb(247, 247, 247);
      border-bottom: 0.01rem solid grey;
      line-height: 0.5rem;
    }
    p{
         font-size: 26px;
         color: #5c6b77;
     }
    .submit{
        font-size: 0.2rem;
        margin-top: 0.1rem;
        border: 0.01rem solid black;
        line-height: 0.6rem;
        height: 0.6rem;
        opacity: 0.8;
    }
    .submit-f{
      margin-top: 0.1rem;
      font-size: 0.2rem;
      line-height: 0.6rem;
      height: 0.6rem;
      border: none;
      background-color: rgb(0, 119, 255);
      color: #fff;
    }
    span{
        font-size: 0.2rem;
        color: #5c6b77;
    }
    .vCode-box{
        position: relative;
    }
    .vCode{
        display: block;
        height: .4rem;
        width: 1rem;
        font-size: .3rem;
        background-color: green;
        color: yellow;
        position: absolute;
        right: .4rem;
        top: 0;
        line-height: .4rem;
        text-align: center;
    }
</style>
